import React, { useContext, useEffect, useMemo, useState } from "react"
import { graphql, useStaticQuery, withPrefix, navigate } from "gatsby"
import Helmet from "react-helmet"
import Select from "react-select"
import { useLazyQuery } from "@apollo/react-hooks"
import { useMediaPredicate } from "react-media-hook"
import { LangContext } from "../../context/langContext"

import { FIND_PROPERTY } from "../../graphql/development-graphql"

import Layout from "../../components/layout"
import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"
import { Container, Section } from "../../components/grid/grid"
import SEO from "../../components/seo"
import desktopLp from "../../assets/img/infinite-living-mobile.jpg"
import { CardProject } from "../../components/card-project/card-project"
import { ReviveAd } from "../../components/ads/reviveAds"
import ribbon from "../../assets/ribbon/ribbon.png"

const changeParentSlug = slug => {
  switch (slug) {
    case "infiniteliving-shophouse":
      slug = "shophouse"
      break
    case "infiniteliving-residential":
      slug = "residential"
      break
    case "infiniteliving-kavling":
      slug = "kavling"
      break
    case "infiniteliving-apartment":
      slug = "apartment"
      break
  }
  return slug
}

const ascPropertyTypesName = (a, b) => {
  const nameA = a.value
  const nameB = b.value

  let comparison
  if (nameA > nameB) {
    comparison = 1
  } else if (nameA < nameB) {
    comparison = -1
  }

  return comparison
}

const getLocationsList = (arr, key) => {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

const discountMap = {
  Imajihaus: 10,
  Impresihaus: 10,
  "The Savia Tahap 1": 10,
  "Visana as The Savia": 10,
  Caelus: 10,
  "Azura Starlight": 10,
  "Batam Landed Ph. 2 (Blok EFGH)": 10,
  Aure: 10,
  "Batam Landed Ph.1 (Blok ABCD)": 20,
  "The Loop": 10,
  "Delrey Bus. Townhouse 2": 10,
  "Taman Tekno X": 10,
  Cheville: 10,
  "Ruko Madrid X": 10,
  Atherton: 10,
  "The Blizfield": 10,
  "Latinos Business Dis. Thp 3": 10,
  "Tanakayu Jiva": 10,
  "Tanakayu Svani": 10,
  "Tanakayu Svadhi": 10,
  "Tanakayu Svasti": 10,
  "Tanakayu Chava": 10,
  "Tanakayu Vasya": 10,
  Aerra: 10,
  "Grand Freja": 10,
  Nordville: 10,
  "New Palladium": 10,
  "London Park Avenue Thp 2": 10,
  "New Alesha": 10,
  "Vanya Avenue": 10,
  Excelia: 10,
  "Virginia Arcade Thp 2": 10,
  "The Elements": 5,
  Kalani: 8,
  Kaina: 8,
  Asatti: 15,
  "Southgate Altuera": 17,
  "Southgate Elegance": 18,
  Aerium: 19.5,
  "Casa de Parco": 20,
  Kavling: 20,
  Alfiore: 20,
  "Kios Pasar Modern": 20,
  Flathouse: 20,
  "Askara Nue": 20,
  "Whitsand II": 20,
  Whelford: 20,
  Zena: 20,
  "Grand Palais": 20,
  "Garden Suite": 20,
  Autoparts: 20,
  "Kios Pasar Modern 2/Barat": 20,
  "Alegria Park": 20,
  Alesha: 20,
  Assana: 20,
  "Southgate Prime": 25,
}

const discountOptions = [
  { value: [5, 15], label: "5-15%" },
  { value: [16, 25], label: "16-25%" },
]

const MoveInQuicklyPage = ({ location }) => {
  const staticData = useStaticQuery(graphql`
    query {
      wordPress {
        propertyType(id: "infiniteliving", idType: SLUG) {
          name
          description
          acfPropertyType {
            sliderImageMobileWdesc {
              ... on WordPress_PropertyType_Acfpropertytype_SliderImageMobileWdesc_Content {
                title
                description
                image {
                  sourceUrl
                }
                link {
                  url
                }
              }
            }
          }
          children {
            nodes {
              name
              slug
              projects(first: 1000) {
                nodes {
                  slug
                  title
                  excerpt
                  featuredImage {
                    sourceUrl
                  }
                  priceProduct {
                    price
                  }
                  locations {
                    nodes {
                      name
                      slug
                    }
                  }
                  projectCustomfields {
                    ribbonDisc
                  }
                  propertyTypes(first: 200) {
                    nodes {
                      name
                      slug
                    }
                  }
                }
              }
            }
          }
        }
        facilities(first: 100) {
          nodes {
            name
            slug
            facilityId
            projects {
              nodes {
                propertyTypes {
                  nodes {
                    slug
                    name
                  }
                }
              }
            }
          }
        }

        locations(first: 100) {
          nodes {
            locationId
            slug
            name
          }
        }

        propertyTypes(first: 100) {
          nodes {
            name
            slug
            propertyTypeId
            children {
              nodes {
                name
                slug
              }
            }
          }
        }
      }
    }
  `)

  let locationSelector = []
  let thisLocations = []
  let propertyTypes = []
  let listProperties = []
  let listPropertyTypesParent = []
  let propertyTypesChecker = []
  const masterProjects = []

  const { lang } = useContext(LangContext)
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const thisCurrrentUrl = process.env.BASE_URL + location.pathname
  const [findProperty, setFindProperty] = useState({
    location: null,
    propertyTypes: null,
    projects: null,
    checkBoxValues: [],
  })

  const wordPress = staticData?.wordPress?.propertyType
  const thisProjects = wordPress?.projects?.nodes
  const listPropertyTypes = staticData.wordPress.propertyTypes.nodes
  const listFacilities = staticData.wordPress.facilities.nodes
  const listLocations = staticData.wordPress.locations.nodes
  let listImagesMobile = wordPress?.acfPropertyType?.sliderImageMobileWdesc

  wordPress?.children?.nodes.forEach(children =>
    children.projects.nodes.forEach(item => {
      let slug = changeParentSlug(children.slug)

      masterProjects.push({
        ...item,
        catName: children.name,
        catSlug: slug,
        discount: item?.projectCustomfields.ribbonDisc,
      })
      item?.locations?.nodes?.forEach(list => {
        locationSelector.push({ label: list.name, value: list.slug })
      })
      item?.propertyTypes?.nodes?.forEach(propertyTypeList => {
        if (propertyTypeList.slug !== "infiniteliving") {
          propertyTypesChecker.push(propertyTypeList.slug)
        }
      })
    })
  )

  let locations = getLocationsList(locationSelector, "value")

  const offset = 0
  const limit = 12
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(limit)
  const [filterKey, setFilterKey] = useState("")
  const [filteredProjects, setFilteredProjects] = useState(masterProjects)
  const [filteredLocations, setFilteredLocations] = useState("")
  const [filteredPromos, setFilteredPromos] = useState(undefined)
  const [filteredPropertyTypes, setFilteredPropertyTypes] = useState("")

  const [
    getProperty,
    { data: properties, loading: loadingProperties },
  ] = useLazyQuery(FIND_PROPERTY)

  const handlerFilterProject = () => {
    let projects = masterProjects

    if (filterKey != "") {
      projects = masterProjects.filter(
        item => item.title.toLowerCase().indexOf(filterKey.toLowerCase()) > -1
      )
    }

    if (filteredPromos) {
      projects = projects.filter(item => {
        if (item.discount) {
          const [from, to] = filteredPromos.value
          const isInRange = item.discount >= from && item.discount <= to
          return isInRange
        }
        return false
      })
    }
    setFilteredProjects(projects)
  }

  const locationHandler = e => {
    setFilteredLocations(e)
  }
  const propertyTypesHandler = e => {
    setFilteredPropertyTypes(e)
  }
  const discHandler = e => {
    setFilteredPromos(e ?? "")
  }

  useEffect(() => {
    handlerFilterProject()
  }, [filteredPromos])

  const handleLoadMore = () => {
    const nextPage = page + 1
    const nextPerPage = nextPage * limit
    console.log({ nextPage, nextPerPage })
    setPage(nextPage)
    setPerPage(nextPerPage)
  }

  thisProjects?.location?.nodes?.map(item =>
    thisLocations.push({ value: item.slug, label: item.name })
  )

  staticData.wordPress.propertyTypes.nodes.map(item =>
    propertyTypes.push({ value: item.slug, label: item.name })
  )

  properties &&
    properties.projects.edges.map(item => {
      listProperties.push({ label: item.node.title, value: item.node.slug })
    })

  listPropertyTypes?.forEach(listItems => {
    if (listItems.slug === "infiniteliving") {
      listItems?.children?.nodes?.forEach(item => {
        let slug = changeParentSlug(item.slug)
        listPropertyTypesParent.push({ label: item.name, value: slug })
      })
    }
  })

  useEffect(() => {
    let facilities = []
    let locations = []
    let propertyTypeId =
      findProperty.propertyTypes &&
      listPropertyTypes.find(x => x.slug === findProperty.propertyTypes.value)
        .propertyTypeId

    findProperty.location &&
      listLocations.map(
        item =>
          item.name === findProperty.location.label &&
          locations.push(item.locationId)
      )

    findProperty.checkBoxValues.length > 0 &&
      findProperty.checkBoxValues.map(item => {
        listFacilities.map(facility => {
          if (facility.slug === item) {
            facilities.push(facility.facilityId)
          }
        })
      })

    if (locations.length > 0 && propertyTypeId) {
      getProperty({
        variables: {
          locationId: locations.length > 0 ? locations : null,
          typeId: [propertyTypeId],
          first: 1000,
          facilityId: facilities.length > 0 ? facilities : null,
        },
      })
    }
  }, [findProperty])

  useEffect(() => {
    if (
      filteredPropertyTypes.value !== undefined &&
      filteredLocations.value !== undefined
    ) {
      navigate(
        "/moveinquickly/" +
          filteredPropertyTypes.value +
          "/" +
          filteredLocations.value
      )
    } else if (
      filteredPropertyTypes.value !== undefined &&
      filteredLocations.value === undefined
    ) {
      navigate("/moveinquickly/" + filteredPropertyTypes.value, {
        state: {
          propertyType: filteredPropertyTypes.value,
        },
      })
    } else if (
      filteredPropertyTypes.value === undefined &&
      filteredLocations.value !== undefined
    ) {
      navigate("/moveinquickly/all-property/" + filteredLocations.value, {
        state: {
          location: filteredLocations.value,
        },
      })
    }
  }, [filteredPropertyTypes, filteredLocations])

  useEffect(() => {
    if (!resizeScreen) {
      document.querySelector(".animated").style.marginTop = "0px"
    } else {
      document.querySelector(".animated").style.marginTop = "60px"
    }
    document.querySelector("#lang-wrapper").classList.remove("d-flex")
    document.querySelector("#lang-wrapper").classList.add("d-none")
  }, [])

  useEffect(() => {
    const desktopNav = document.querySelector("#desktopNav")
    const mobileNav = document.querySelector("#navbarMobile .navbar-nav-main")
    if (!resizeScreen) {
      desktopNav.style.backgroundColor = "white"
    } else {
      mobileNav.style.backgroundColor = "white"
    }
  }, [])

  const [menuPortalTarget, setMenuPortalTarget] = useState(undefined)

  useEffect(() => {
    let navbarDesktop = document
      .getElementById("desktopNav")
      .querySelectorAll(".nav-link")

    if (resizeScreen) {
      document.getElementsByClassName(
        "logo-secondary"
      )[1].style.display = `none`
      document.getElementsByClassName("logo-main")[1].style.display = `block`
    } else {
      document.getElementsByClassName(
        "logo-secondary"
      )[0].style.display = `none`
      document.getElementsByClassName("logo-main")[0].style.display = `block`
    }

    for (let i = 0; i < navbarDesktop.length; i++) {
      navbarDesktop[i].style.color = "#000"
    }
    setMenuPortalTarget(document?.body)
  }, [])

  return (
    <Layout location={location} lang="EN">
      <SEO
        title={`Move in Quickly - Program Nasional Sinar Mas Land 2025`}
        fbTitle={`Move in Quickly - Program Nasional Sinar Mas Land 2025`}
        twitterTitle={`Move in Quickly - Program Nasional Sinar Mas Land 2025`}
        twitterDescription={`Move in Quickly adalah program promo dari Sinarmasland dengan berbagai promo menarik untuk pelanggan. Segera hubungi tim kami untuk dapatkan hunian impian keluarga Anda.`}
        fbDescription={`Move in Quickly adalah program promo dari Sinarmasland dengan berbagai promo menarik untuk pelanggan. Segera hubungi tim kami untuk dapatkan hunian impian keluarga Anda.`}
        description={`Move in Quickly adalah program promo dari Sinarmasland dengan berbagai promo menarik untuk pelanggan. Segera hubungi tim kami untuk dapatkan hunian impian keluarga Anda.`}
        url={thisCurrrentUrl}
        fbImg={
          listImagesMobile[0]?.image?.sourceUrl
            ? listImagesMobile[0]?.image?.sourceUrl
            : desktopLp
        }
        twitterImg={
          listImagesMobile[0]?.image?.sourceUrl
            ? listImagesMobile[0]?.image?.sourceUrl
            : desktopLp
        }
        img={
          listImagesMobile[0]?.image?.sourceUrl
            ? listImagesMobile[0]?.image?.sourceUrl
            : desktopLp
        }
      />
      <Helmet>
        <script src={withPrefix("slider.js")} type="text/javascript" />
      </Helmet>
      <Section
        className="header-smartmove-banner p-0 overflow-x-hidden"
        style={{ padding: "0px" }}
      >
        <div className="d-none d-lg-block">
          <ReviveAd zoneId="4" reviveId="4d3dce4db16ff385dd1c5dd5ade84a88" />
        </div>
        <div className="d-block d-lg-none">
          <ReviveAd zoneId="22" reviveId="4d3dce4db16ff385dd1c5dd5ade84a88" />
        </div>
      </Section>
      <Section className="main-properti-smartmove">
        <Container className="mb-3">
          <div className="row">
            <div className="col-md-12">
              <Container id="search-bar" className="mb-5">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    id="search-properti"
                    className="form-control"
                    placeholder="Cari properti disini"
                    value={filterKey}
                    onChange={e => setFilterKey(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => handlerFilterProject()}
                    >
                      {lang === "EN" ? `Search` : `Cari`}
                    </button>
                  </div>
                </div>
              </Container>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex align-items-center">
                <i className="fas fa-landmark form-icon-fa"></i>
                <Select
                  id="propertytypes"
                  className="form-select z-3 w-100"
                  classNamePrefix="select"
                  menuPortalTarget={menuPortalTarget}
                  placeholder="Property Types"
                  options={listPropertyTypesParent}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e5e5e5",
                      primary: "#191919",
                    },
                  })}
                  onChange={e => propertyTypesHandler(e)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex align-items-center">
                <i className="fas fa-map-marker-alt form-icon-fa"></i>
                <Select
                  id="location"
                  className="form-select z-3 w-100"
                  menuPortalTarget={menuPortalTarget}
                  classNamePrefix="select"
                  placeholder="Location"
                  options={locations.sort(ascPropertyTypesName)}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e5e5e5",
                      primary: "#191919",
                    },
                  })}
                  onChange={e => locationHandler(e)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex align-items-center">
                <i className="fas fa-house form-icon-fa"></i>
                <Select
                  isClearable
                  id="discount_amount"
                  className="form-select z-3 relative w-100"
                  classNamePrefix="select"
                  menuPortalTarget={menuPortalTarget}
                  placeholder="Discount amount"
                  options={discountOptions}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#e5e5e5",
                      primary: "#191919",
                    },
                  })}
                  onChange={e => discHandler(e)}
                />
              </div>
            </div>
            <div className="col-md-12 mb-3 mt-3">
              <ul className="faq-nav">
                <li className="item">
                  <a
                    className="faq-link"
                    href="/moveinquickly/faq"
                    style={{ color: "#ab0204 !important" }}
                  >
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </Section>
      <div className="smartmove-list-projects">
        <div className="smartmove-bg"></div>
        {filteredProjects && filteredProjects.length > 0 ? (
          <>
            <div className="row col-md-12 m-0 p-0">
              {filteredProjects.slice(offset, perPage).map(item => {
                let ptChecker = ""
                item?.propertyTypes?.nodes.forEach(thisProperty => {
                  if (thisProperty.slug !== "infiniteliving") {
                    ptChecker = thisProperty.slug
                  }
                })
                return (
                  <div
                    className="col-md-4 p-3"
                    style={{ position: "relative" }}
                  >
                    {!!item.discount && (
                      <div
                        style={{
                          position: "absolute",
                          top: 20,
                          right: -19,
                          zIndex: 99,
                        }}
                      >
                        <div style={{ position: "relative" }}>
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <p
                              style={{
                                fontSize: 18,
                                marginLeft: 15,
                                marginTop: 20,
                                color: "#e3e3e3",
                              }}
                            >
                              Disc {item.discount}%
                            </p>
                          </div>
                          <img
                            src={ribbon}
                            alt={item.discount}
                            style={{ width: 200 }}
                          />
                        </div>
                      </div>
                    )}
                    <CardProject
                      title={item.title || null}
                      description={item?.excerpt}
                      img={item?.featuredImage?.sourceUrl || null}
                      category={item.catName || null}
                      location={item?.locations?.nodes[0]?.name || null}
                      link={
                        ptChecker !== ""
                          ? `/development/${ptChecker}/${item.slug}`
                          : `/infiniteliving/${item.catSlug}/${item?.locations?.nodes[0]?.slug}/${item.slug}`
                      }
                      className="px-sm-down-0 ratio-1-1"
                    />
                  </div>
                )
              })}
            </div>

            <div className="col-md-12 pb-5 text-center">
              {filteredProjects.length > perPage ? (
                <button
                  className="btn btn-primary w-100 w-md-25"
                  onClick={handleLoadMore}
                >
                  Load More
                </button>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <div className="col-md-12" style={{ padding: "100px 0px" }}>
            <p className="text-center font-weight-bold">
              The product not available
            </p>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default MoveInQuicklyPage
